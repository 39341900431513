import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from '@core/core.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MomentModule } from 'ngx-moment';
import { AuthService } from '@core/services/auth.service';

function initializeAppFactory(_authService: AuthService) {
	return () => _authService.appInitialize();
}
@NgModule({
	declarations: [AppComponent],
	imports: [
		MomentModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		CoreModule.forRoot()
	],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			useFactory: initializeAppFactory,
			deps: [AuthService, Sentry.TraceService],
			multi: true
		},
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false
			})
		},
		{
			provide: Sentry.TraceService,
			deps: [Router]
		},
		DatePipe
	]
})
export class AppModule {}
