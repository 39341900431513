import { Component } from '@angular/core';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { environment } from '@environment/environment';

@Component({
	selector: 'soc-app',
	templateUrl: './app.component.html'
})
export class AppComponent {
	constructor() {
		document.getElementsByTagName('body')[0].id = environment.env;
	}
}
